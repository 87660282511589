import type { SeverEnv } from './types'

import getConfig from 'next/config'

/* NOTE: next/config provide an 'any' return type */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { serverRuntimeConfig = {} } = getConfig() || {}

const buildTimeENV = serverRuntimeConfig as unknown as SeverEnv

export default buildTimeENV
