import type { AppDispatch, AppState } from './store'
import type { TypedUseSelectorHook } from 'react-redux'

import { useDispatch as defaultUseDispatch, useSelector as defaultUseSelector } from 'react-redux'

import { wrapper } from './store'

export type { AppState, AppDispatch, Selector } from './store'

export const useDispatch = () => defaultUseDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<AppState> = defaultUseSelector

export const {
  getServerSideProps: getStoreServerSideProps,
  getStaticProps: getStoreStaticProps,
  withRedux,
} = wrapper

export { initialStoreState } from './store'
