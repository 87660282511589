import type { ISendDirectMessageReqData, SendDirectMessageResponse } from './types'
import type { PostLeadRCMBody } from 'api/instances/rcm'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { userApiInstance } from 'api'
import { sendRcm } from 'store/public-messaging/utils'

import { NAME } from './constants'

export const sendDirectMessage = createAsyncThunk(
  `${NAME}/SEND_DIRECT_MESSAGE`,
  async ({
    recipient,
    message,
    sender,
    source,
    agentProfileOwnerId,
  }: ISendDirectMessageReqData) => {
    const postLeadRCMBody: PostLeadRCMBody = {
      email: sender.email ?? '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      owner_id: agentProfileOwnerId,
      firstName: sender.firstName ?? '',
      mobileNumber: sender.phone,
      source,
      automated: true,
    }
    const response = await userApiInstance.put<unknown, SendDirectMessageResponse>(
      '/messaging/conversations/send-message',
      { recipient, message },
    )
    sendRcm({ postLeadRCMBody }).catch(() => true)
    return response
  },
)

export const asyncActions = {
  sendDirectMessage,
}
