import type { IAgentReviewList, AgentReviewListState } from './types'
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { asyncActions, getAgentReviewList } from './async-actions'
import { NAME } from './constant'

export const initialState: AgentReviewListState = {
  error: null,
  status: 'initial',
  data: {
    data: [],
    pagination: {},
  },
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setAgentProfile(state, action: PayloadAction<IAgentReviewList>) {
      state.data = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AgentReviewListState>): void => {
    builder.addCase(getAgentReviewList.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getAgentReviewList.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    })
    builder.addCase(getAgentReviewList.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: slice.reducer }
