import type { AgentReviewPage, AgentReviewPageState } from './types'
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { asyncActions, getAgentReviewPage } from './async-actions'
import { NAME } from './constant'

export const initialState: AgentReviewPageState = {
  error: null,
  status: 'initial',
  data: {},
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setAgentReviewPage(state, action: PayloadAction<AgentReviewPage>) {
      state.data = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AgentReviewPageState>): void => {
    builder.addCase(getAgentReviewPage.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getAgentReviewPage.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    })
    builder.addCase(getAgentReviewPage.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: slice.reducer }
