export const STORAGE = {
  USER_TOKEN: 'rentspreeUserToken',
}

type CookieOption = {
  path: string
  domain?: string
}

// DOMAIN NAME HAS TO BE OMITTED FOR SHARE COOKIE TO WORK ON LOCALHOST
export const cookieOption = (domainName: string): CookieOption => ({
  path: '/',
  ...(domainName !== 'localhost' && { domain: domainName }),
})

export const TERMS_OF_USE_URL = 'https://www.rentspree.com/terms-of-use/'
export const PRIVACY_POLICY_URL = 'https://www.rentspree.com/rentspree-privacy-policy/'
export const SUPPORT_PHONE = '3235157757'
export const SUPPORT_EMAIL = 'support@rentspree.com'
export const socialContacts: ISocialContact[] = [
  {
    img: 'footer-facebook.svg',
    link: 'https://www.facebook.com/rentspree',
  },
  {
    img: 'footer-linkedin.svg',
    link: 'https://www.linkedin.com/company/rentspree',
  },

  {
    img: 'footer-instagram.svg',
    link: 'https://www.instagram.com/rentspree/',
  },
  {
    img: 'footer-youtube.svg',
    link: 'https://www.youtube.com/c/rentspree',
  },
  {
    img: 'footer-twitter.svg',
    link: 'https://twitter.com/rentspree',
  },
]

export const GCS_PROTOCOL = 'gs://'
export const GOOGLE_CLOUD_STORAGE_HOST = 'https://storage.googleapis.com/'

export const SUPPORTED_SOCIAL_MEDIA = ['facebook', 'twitter', 'linkedin', 'email'] as const

export const REVIEW_GUIDELINE_URL =
  'https://support.rentspree.com/en/rentspree-agent-review-guidelines?hs_preview=EwNyPjmy-85452855387'
