type AdjustedContactInformationURLs = {
  [k in Exclude<keyof IContactInformation, 'brokerage'>]?: string
}

export const adjustUrl = (
  contactInformation: IContactInformation,
): AdjustedContactInformationURLs => {
  const adjustUrlKey: (keyof AdjustedContactInformationURLs)[] = [
    'facebookUrl',
    'instagramUrl',
    'linkedInUrl',
    'twitterUrl',
    'tiktokUrl',
    'primaryWebsiteUrl',
    'secondaryWebsiteUrl',
  ]
  return adjustUrlKey.reduce((adjustingValue, key): AdjustedContactInformationURLs => {
    const url = contactInformation[key]
    let urlAdjusted = url
    if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
      urlAdjusted = `https://${url}`
    }
    return {
      ...adjustingValue,
      [key]: urlAdjusted,
    }
  }, {})
}
