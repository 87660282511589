import type { IAgentContactInfoData, AgentProfileState, IContactInformation } from './types'
import type { Selector, AppState } from 'store'

import { createSelector } from '@reduxjs/toolkit'
import { generateFullName, formatLocalPhone } from '@rentspree/helper'

import env from 'env'

import { NAME } from './constant'
import { adjustUrl } from './utils'

export const selectAgentProfileState: Selector<AgentProfileState> = (state: AppState) => state[NAME]

export const selectAgentProfile: Selector<IAgentProfile> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) => agentProfileState.data,
)

export const selectAgentProfileStatus: Selector<RequestStatus> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) => agentProfileState.status || 'initial',
)

export const selectAgentProfileImage: Selector<string> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) =>
    agentProfileState.data.image || '/profile/default-profile.svg',
)

export const selectAgentCoverImage: Selector<string> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) =>
    agentProfileState.data?.coverImage || '/profile/default-cover-image.png',
)

export const selectAgentProfileAboutMe: Selector<IAgentProfileAboutMe> = createSelector(
  selectAgentProfileState,
  (state: AgentProfileState) => {
    const { firstName, lastName, headline, aboutMe, coverImage } = state.data
    return {
      firstName,
      lastName,
      headline,
      aboutMe,
      coverImage,
    }
  },
)

export const selectAgentProfileFullName: Selector<string> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) => {
    const { firstName, lastName } = agentProfileState.data
    return generateFullName({ firstName, lastName }) || 'Agent'
  },
)

export const selectAgentProfileHeaderTitle: Selector<string> = createSelector(
  [selectAgentProfileState, selectAgentProfileFullName],
  (agentProfileState: AgentProfileState, fullName: string) => {
    const { headline } = agentProfileState.data
    const headerTitle = `${fullName}${headline ? ` ${headline}` : ''}`
    return headerTitle
  },
)

export const selectAgentVerifiedStatus: Selector<boolean> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) => agentProfileState?.data?.isVerified ?? false,
)

export const selectAgentContactInfo: Selector<IAgentContactInfoData> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) => {
    let phone: string | undefined
    try {
      // TO-DO: fix 'formatLocalPhone' in @rentspree/helper to accept only string
      phone = formatLocalPhone(agentProfileState.data.phone ?? '')
    } catch (err) {
      phone = agentProfileState.data.phone
    }
    return {
      contactInformation: agentProfileState.data.contactInformation,
      phone,
      email: agentProfileState.data.email,
    }
  },
)

export const selectAgentProfileContactInformationWithAdjustUrl: Selector<IContactInformation> =
  createSelector(selectAgentProfileState, (agentProfileState: AgentProfileState) => ({
    ...adjustUrl(agentProfileState.data.contactInformation ?? {}),
  }))

export const selectAgentProfileExperience: Selector<AgentProfileExperience> = createSelector(
  selectAgentProfileState,
  (agentProfileState: AgentProfileState) =>
    agentProfileState.data.experience ?? {
      agentLicenses: [],
      certificates: [],
      brokerageLicense: '',
    },
)

export const selectAgentSpecialty: Selector<IAgentSpecialty> = createSelector(
  selectAgentProfileState,
  (state: AgentProfileState) => state.data.specialty || {},
)

export const selectSharingMetaProperties: Selector<ISharingMetaProperties> = createSelector(
  selectAgentProfile,
  ({ firstName, lastName, headline, contactInformation, aboutMe, slug, coverImage, specialty }) => {
    let title = `${firstName ?? ''} ${lastName ?? ''}`
    const { serviceAreas } = specialty || {}
    if (headline) {
      title += ` - ${headline}`
    }
    if (contactInformation?.brokerage?.name) {
      title += ` - ${contactInformation?.brokerage?.name}`
    }
    if (serviceAreas !== undefined && serviceAreas.length > 0) {
      title += ` | ${serviceAreas[0]}`
    }

    return {
      title,
      description: aboutMe || '',
      url: `${env.DOMAIN_NAME}/profile/${slug ?? ''}`,
      image: coverImage || '',
    }
  },
)

export const selectAgentProfileEmail: Selector<string | undefined> = createSelector(
  selectAgentProfile,
  (agentProfile: IAgentProfile) => agentProfile.email,
)

export const selectAgentProfileOwnerId: Selector<string | undefined> = createSelector(
  selectAgentProfile,
  ({ userId }) => userId,
)
