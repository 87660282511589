import type { IAnyError } from '@rentspree/axios-refresh-token'
import type { AxiosResponse } from 'axios'

import { axiosResponse } from '@rentspree/axios'
import axios from 'axios'

import { handleAuthorization } from 'api/api-handler'
import env from 'env'

import { request } from '../request'

type AxiosResponseInterceptor = (res: AxiosResponse) => AxiosResponse['data']

/* NOTE: line error on @rentspree/axios@typescript */
// eslint-disable-next-line
const axiosResponseInterceptor: AxiosResponseInterceptor = axiosResponse.response

const agentReviewApiInstance = axios.create({
  baseURL: env.AGENT_REVIEW_API_BASE_URL,
})

agentReviewApiInstance.interceptors.request.use(request)
agentReviewApiInstance.interceptors.response.use(axiosResponseInterceptor, (error: IAnyError) =>
  handleAuthorization(error),
)

export { agentReviewApiInstance }

export default agentReviewApiInstance
