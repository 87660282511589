import type {
  Slice,
  SliceCaseReducers,
  CreateSliceOptions,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit'

import { createSlice as defaultCreateSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

export function createSlice<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
>(
  name: string,
  options: CreateSliceOptions<State, CaseReducers, Name>,
): Slice<State, CaseReducers, Name> {
  const interceptedOptions = options
  const { extraReducers } = options
  if (!extraReducers || typeof extraReducers === 'object') {
    interceptedOptions.extraReducers = {
      ...extraReducers,
      /* eslint-disable */
      [HYDRATE]: (state, action) => ({
        ...state,
        ...action.payload[name],
      }),
      /* eslint-enable */
    }
  } else if (typeof extraReducers === 'function') {
    const currentExtraReducers: (builder: ActionReducerMapBuilder<State>) => void = extraReducers
    interceptedOptions.extraReducers = (builder: ActionReducerMapBuilder<State>): void => {
      currentExtraReducers(builder)
      /* eslint-disable */
      builder.addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          // @ts-ignore
          ...action.payload[name],
        }
      })
      /* eslint-enable */
    }
  }
  const slice = defaultCreateSlice(interceptedOptions)
  return slice
}
