import type { ListingListState } from './types'

export const initialState: ListingListState = {
  error: null,
  status: 'initial',
  data: {
    data: [],
    pagination: {
      total: 0,
    },
  },
}
