import type { UserState } from './types'
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { createSlice } from '@reduxjs/toolkit'

import { getUserProfile, asyncActions } from './async-actions'
import { NAME } from './constant'

export const initialState: UserState = {
  error: null,
  status: 'initial',
  data: {
    profile: {},
    userIntegration: [],
  },
}

export const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event: (_state, _action) => {
      // do nothing
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserState>): void => {
    builder.addCase(getUserProfile.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.data.profile = action.payload.result
    })
    builder.addCase(getUserProfile.rejected, (state, action) => {
      state.status = 'rejected'

      /* NOTE: unknown error type from thunk */
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'
export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: reducer }
