/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { Middleware } from '@reduxjs/toolkit'
import type { RootState } from 'store/reducer'
import type { IGetProfileResponse } from 'store/users/types'

import { actions } from 'store/users'
import tracker from 'tracker'

export const trackerIdentifyUserMiddleware: Middleware<unknown, RootState> =
  () => next => action => {
    if (action.type === actions.getUserProfile.fulfilled.type) {
      const actionPayload = action.payload as unknown as IGetProfileResponse
      tracker?.identifyUser(actionPayload.result || {})
    }
    return next(action)
  }
