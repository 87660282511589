import type { IAnyError } from '@rentspree/axios-refresh-token'
import type { AxiosResponse } from 'axios'

import { axiosResponse } from '@rentspree/axios'
import axios from 'axios'

import env from 'env'

import { handleAuthorization } from '../api-handler'
import { request } from '../request'

type AxiosResponseInterceptor = (res: AxiosResponse) => AxiosResponse['data']

/* NOTE: line error on @rentspree/axios@typescript */
// eslint-disable-next-line
const axiosResponseInterceptor: AxiosResponseInterceptor = axiosResponse.response

const userApiInstance = axios.create({
  baseURL: env.USER_API_BASE_URL,
})

userApiInstance.interceptors.request.use(request)
userApiInstance.interceptors.response.use(axiosResponseInterceptor, (error: IAnyError) =>
  handleAuthorization(error),
)

export { userApiInstance }
