import type { IPublicMessagingState, ISendEmailToAgentError } from './types'
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { asyncActions, sendEmailToAgent } from './async-actions'
import { NAME } from './constant'

export const initialState: IPublicMessagingState = {
  error: null,
  publicMessageStatus: 'initial',
  scheduleTourStatus: 'initial',
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IPublicMessagingState>): void => {
    builder.addCase(sendEmailToAgent.pending, state => {
      state.publicMessageStatus = 'pending'
      state.error = null
    })
    builder.addCase(sendEmailToAgent.fulfilled, state => {
      state.publicMessageStatus = 'fulfilled'
    })
    builder.addCase(sendEmailToAgent.rejected, (state, action) => {
      state.publicMessageStatus = 'rejected'
      state.error = action.payload as ISendEmailToAgentError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: reducer }
