import type { ThemeOptions } from '@mui/material/styles'
import type { AppProps } from 'next/app'
import type { StatsigInitializeValues } from 'utils/feature-flag/instance'

import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { muiTheme } from '@rentspree/component-2023.theme.mui'
import Head from 'next/head'
import Script from 'next/script'
import { IntlProvider } from 'react-intl'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { useAuthorizeUserTokenEffect } from '@hooks'
import { FeatureFlagProvider } from 'containers/feature-flag'
import env from 'env'
import { withRedux } from 'store'
import GlobalStyle from 'styles/index'
import styleTheme from 'styles/theme'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import packageJson from '../../package.json'

import 'pages/profile/[slug]/profile-slug.scss'
import 'containers/professional-profile-header/professional-profile-header.scss'
import 'containers/agent-profile/review/review-summary/review-summary.scss'
import 'containers/agent-profile/review/review-list.scss'

if (
  typeof window !== 'undefined' &&
  env.DATADOG_CLIENT_TOKEN &&
  env.DATADOG_APPLICATION_ID &&
  env.DATADOG_SITE
) {
  datadogRum.init({
    applicationId: env.DATADOG_APPLICATION_ID,
    clientToken: env.DATADOG_CLIENT_TOKEN,
    site: env.DATADOG_SITE,
    service: packageJson.name,
    env: env.ENVIRONMENT,
    version: packageJson.version,
    sessionSampleRate: parseInt(env.DATADOG_SAMPLE_RATE || '0', 10),
    sessionReplaySampleRate: parseInt(env.DATADOG_REPLAY_SAMPLE_RATE || '0', 10),
    traceSampleRate: parseInt(env.DATADOG_TRACE_SAMPLE_RATE || '0', 10),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      { match: env.API_URL || '', propagatorTypes: ['b3', 'b3multi'] },
      { match: env.USER_API_URL || '', propagatorTypes: ['b3', 'b3multi'] },
    ],
    excludedActivityUrls: [
      /^https:\/\/api\.amplitude\.com/,
      /^https:\/\/analytics\.google\.com/,
      /^https:\/\/.*\.clarity\.ms\/collect/,
      /^https:\/\/settings\.luckyorange\.net/,
      /^https:\/\/.*\.talkjs\.com/,
      /^https:\/\/status\.rentspree\.com\/embed\/script\.js/,
      /^https:\/\/.*\.statuspage\.io/,
      /^https:\/\/.*\.hs-scripts\.com/,
      /^https:\/\/.*\.hs-banner\.com/,
      /^https:\/\/.*\.hs-analytics\.net/,
      /^https:\/\/.*\.hubspotfeedback\.com/,
      /^https:\/\/.*\.hubspot\.com/,
      /^https:\/\/.*\.hsappstatic\.net/,
      /^https:\/\/.*\.hsforms\.com/,
      /^https:\/\/.*\.hsforms\.net/,
      /^https:\/\/.*\.hsleadflows\.net/,
    ],
  })
}

const App = (props: AppProps): JSX.Element => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
  const { Component, pageProps } = props
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
  const { initializeValues } = pageProps

  const theme = createTheme(muiTheme as ThemeOptions)

  useAuthorizeUserTokenEffect()
  return (
    <>
      <Script>
        {`;(function(w, d, s, l, i) {
              w[l] = w[l] || []
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : ""
              j.async = true
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
              f.parentNode.insertBefore(j, f)
            })(
            window,
            document,
            "script",
            "dataLayer",
            "${env.GOOGLE_TAG_MANAGER_CONTAINER_ID}",)`}
      </Script>
      <Head>
        <title>RentSpree</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Merriweather:400,700"
        />
        <link
          rel="shortcut icon"
          href="https://cdn.rentspree.com/static-files/favicon/rentspree.ico"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://cdn.rentspree.com/static-files/component-2023/css/design-tokens@2.2.13.css"
        />
      </Head>
      <GlobalStyle />
      <StyledThemeProvider theme={styleTheme}>
        <ThemeProvider theme={theme}>
          <IntlProvider locale="en" defaultLocale="en">
            <FeatureFlagProvider initializeValues={initializeValues as StatsigInitializeValues}>
              <Component {...pageProps} />
            </FeatureFlagProvider>
          </IntlProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </>
  )
}

export default withRedux(App)
