import { useEffect } from 'react'

import { useSelector } from 'store'
import { selectAgentProfileOwnerId } from 'store/agent-profile'
import tracker, { events } from 'tracker'

export const useSendPublicProfilePageViewEvent = (isPreview = false): void => {
  const agentId = useSelector(selectAgentProfileOwnerId)

  useEffect(() => {
    if (!isPreview && !!agentId) {
      tracker?.trackEvent(events.PUBLIC_PAGE.ENTER_PUBLIC_PROFILE, { agentId })
    }
  }, [isPreview, agentId])
}
