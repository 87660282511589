import type { GetAgentReviewPageResponse, GetAgentReviewPageError } from './types'
import type { AxiosResponse } from 'axios'

import { sanitizeUrl } from '@braintree/sanitize-url'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { agentReviewApiInstance } from 'api'

import { NAME } from './constant'

export interface IPayload {
  agentReviewId: string
  agentProfileSlug: string
}

export const getAgentReviewPage = createAsyncThunk(
  `${NAME}/GET_AGENT_REVIEW_PAGE`,
  async (payload: IPayload, { rejectWithValue }) => {
    const { agentProfileSlug, agentReviewId } = payload

    try {
      const sanitizedSlug = !isEmpty(agentProfileSlug)
        ? sanitizeUrl(agentProfileSlug)
        : agentProfileSlug

      const response = await agentReviewApiInstance.get<unknown, GetAgentReviewPageResponse>(
        `/public/${sanitizedSlug}/review/${agentReviewId}`,
      )
      return response
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const error = e as AxiosResponse<GetAgentReviewPageError>
      return rejectWithValue(error.data)
    }
  },
)

export const asyncActions = {
  getAgentReviewPage,
}
