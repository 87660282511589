import type { IAgentReviewSummary, AgentReviewSummaryState } from './types'
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { asyncActions, getAgentReviewSummary } from './async-actions'
import { NAME } from './constant'

export const defaultSummaryData: IAgentReviewSummary = {
  average: 0,
  totalReviews: 0,
  star: {},
  compliments: [],
  status: {
    pending: 0,
    published: 0,
    total: 0,
  },
}
export const initialState: AgentReviewSummaryState = {
  error: null,
  status: 'initial',
  data: defaultSummaryData,
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setAgentProfile(state, action: PayloadAction<IAgentReviewSummary>) {
      state.data = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AgentReviewSummaryState>): void => {
    builder.addCase(getAgentReviewSummary.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getAgentReviewSummary.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    })
    builder.addCase(getAgentReviewSummary.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: slice.reducer }
