import type {
  IContactRCMPublicResponse,
  IContactRCMPublicContactEvent,
  ISendRcmPayload,
} from './types'

import { set } from 'lodash'

import { rcmApiInstance } from 'api'

export const sendRcm = async ({ postLeadRCMBody, property }: ISendRcmPayload): Promise<void> => {
  const responseRcm: IContactRCMPublicResponse = await rcmApiInstance.post('/public/contacts', {
    ...postLeadRCMBody,
    meta: { ...(property ? { property: { id: property.id } } : {}) },
  })
  const eventProperties: IContactRCMPublicContactEvent = {}
  if (postLeadRCMBody.source === 'listing-syndication') {
    set(eventProperties, 'property', {
      id: property?.id,
      address: property?.address,
    })
  }

  return rcmApiInstance.post(`/public/contacts/${responseRcm?._id}/activities/ensure`, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_id: postLeadRCMBody.owner_id,
    templateName: 'first_incoming_msg_from_agent_profile',
    eventName:
      postLeadRCMBody.source === 'agent-profile'
        ? 'contact-from-agent-profile'
        : 'contact-from-listing-syndication',

    eventProperties,
  })
}

export const sendRCMWithOutActivities = ({
  postLeadRCMBody,
  property,
}: ISendRcmPayload): Promise<void> =>
  rcmApiInstance.post('/public/contacts', {
    ...postLeadRCMBody,
    meta: { ...(property ? { property: { id: property.id } } : {}) },
  })
