import type {
  IAgentReviewState,
  IAgentReviewData,
  IAgentReviewFetchError,
  IAgentBadgeInformationDisplayName,
} from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constant'

export const selectAgentReviewState: Selector<IAgentReviewState> = state => state[NAME]

export const selectAgentReview: Selector<IAgentReviewData> = createSelector(
  selectAgentReviewState,
  agentProfileState => agentProfileState.data || {},
)

export const selectAgentReviewBadgeInformation: Selector<IAgentBadgeInformationDisplayName> =
  createSelector(selectAgentReviewState, agentProfileState => {
    const { agentProfile } = agentProfileState.data
    const isNameExist = agentProfile?.firstName || agentProfile?.lastName
    const displayName = isNameExist
      ? `${agentProfile?.firstName || ''}  ${agentProfile?.lastName || ''}`
      : agentProfile?.email
    const isVerified = isNameExist ? agentProfile.isVerified : undefined
    const image = agentProfile?.image
    const agentInformation = [
      agentProfile?.phone,
      agentProfile?.email,
      agentProfile?.contactInformation?.brokerage?.name,
    ]

    let information: string | undefined
    if (agentProfile && agentProfile.status !== 'initial' && isNameExist) {
      information = agentInformation.filter(item => item).join(' | ')
    }
    return { displayName, image, information, isVerified }
  })

export const selectFetchingStatus: Selector<RequestStatus> = createSelector(
  selectAgentReviewState,
  agentProfileState => agentProfileState.fetchingStatus || 'initial',
)

export const selectFetchingError: Selector<IAgentReviewFetchError | null> = createSelector(
  selectAgentReviewState,
  agentProfileState => agentProfileState.fetchingError || null,
)

export const selectSubmittingStatus: Selector<RequestStatus> = createSelector(
  selectAgentReviewState,
  agentProfileState => agentProfileState.submittingStatus || 'initial',
)

export const selectSubmittingError: Selector<RequestError | null> = createSelector(
  selectAgentReviewState,
  agentProfileState => agentProfileState.submittingError || null,
)
