import type { IAnyError } from '@rentspree/axios-refresh-token'

import getHandleAuthorization from '@rentspree/axios-refresh-token'
import { removeLocalItem } from '@rentspree/cookie'

import { STORAGE } from 'constant'
import env from 'env'

export const afterRetry = (error: IAnyError) => Promise.reject(error.response ?? error)
export const afterRefreshTokenFailure = (error: IAnyError) => {
  removeLocalItem(STORAGE.USER_TOKEN)
  return Promise.reject(error.response ?? error)
}

export const handleAuthorization = getHandleAuthorization({
  afterRetry,
  afterRefreshTokenFailure,
  domain: env.DOMAIN_NAME,
  authAPIBase: env.USER_API_URL,
})
