import { useEffect } from 'react'

import { useDispatch } from 'store'
import { actions } from 'store/users'
import { loadAccessToken, removeAccessToken } from 'utils/access-token'

export const useAuthorizeUserTokenEffect = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    const userAccessToken = loadAccessToken()
    const isUserLoggedIn = userAccessToken.accessToken
    if (isUserLoggedIn) {
      // eslint-disable-next-line no-void
      void dispatch(actions.getUserProfile())
    } else {
      removeAccessToken()
    }
  }, [dispatch])
}
