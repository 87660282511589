import type { IAnalyticDataState } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { NAME } from './constant'

export const initialState: IAnalyticDataState = {}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setAnalyticData(state, action: PayloadAction<IAnalyticDataState>) {
      return { ...state, ...action.payload }
    },
  },
})

export const { reducer } = slice
export * from './selectors'

export const { actions } = slice

export default { [NAME]: slice.reducer }
