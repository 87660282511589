/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EventDefinition, EventsMap } from 'redux-beacon'

import { createMiddleware } from 'redux-beacon'

import { actions } from 'store/users'
import tracker from 'tracker'

export type EventType = {
  eventName: string
  eventProperties?: any
}

type TrackEventType = <A = { [key: string]: any }, S = any>(
  eventDef: EventDefinition<EventType, A, S>,
) => EventDefinition<any, A, S>

const trackEvent: TrackEventType = eventDef => (action, prevState, nextState) =>
  eventDef(action, prevState, nextState)

const eventsMap: EventsMap = {
  [actions.event.type]: trackEvent(action => {
    const { eventName, eventProperties } = action.payload
    return {
      eventName,
      eventProperties,
    }
  }),
}

const target = (events: EventType[]) => {
  events.map(event => tracker && tracker.trackEvent(event.eventName, event.eventProperties))
}

export const trackerMiddleware = createMiddleware(eventsMap, target)
