import type { IAgentReviewData, ISubmitReviewPayload } from './types'
import type { AxiosRequestHeaders } from 'axios'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { agentReviewApiInstance } from 'api'

import { NAME } from './constant'

export const getAgentReview = createAsyncThunk(
  `${NAME}/GET_AGENT_REVIEW`,
  async (token: string | null, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const headers: AxiosRequestHeaders = token ? { 'x-review-token': token } : {}
      const response = await agentReviewApiInstance.get<unknown, IAgentReviewData>('/public', {
        headers,
      })
      return response
    } catch (e) {
      const { data } = e as { data: RequestError }
      return rejectWithValue(data)
    }
  },
)

export const submitAgentReview = createAsyncThunk(
  `${NAME}/SUBMIT_AGENT_REVIEW`,
  async (payload: ISubmitReviewPayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const headers: AxiosRequestHeaders = payload.token ? { 'x-review-token': payload.token } : {}
      const response = await agentReviewApiInstance.put<unknown, IAgentReviewData>(
        '',
        payload.data,
        {
          headers,
        },
      )
      return response
    } catch (e) {
      const { data } = e as { data: RequestError }
      return rejectWithValue(data)
    }
  },
)

export const asyncActions = {
  getAgentReview,
  submitAgentReview,
}
