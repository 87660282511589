import type { ListingListState } from './types'
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { createSlice } from '@redux'
import { getAgentProfile } from 'store/agent-profile/async-actions'

import { NAME } from './constant'
import { initialState } from './initial-state'

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ListingListState>): void => {
    builder.addCase(getAgentProfile.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload.listingList
    })
    builder.addCase(getAgentProfile.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
}

export default { [NAME]: slice.reducer }
