import type { PropertyTypeDisplayName } from './types'

export const selectPropertyTypesByNumber = (n: number): PropertyType => {
  const types: PropertyType[] = [
    'N/A',
    'SINGLE_FAMILY_HOME',
    'MULTI_FAMILY_HOME',
    'CONDOMINIUM',
    'APARTMENT',
    'MANUFACTURED_HOME',
    'TOWNHOUSE',
    'STUDENT_HOUSING',
    'OTHERS',
  ]
  return types[n % types.length]
}

export const selectPropertyTypeDisplayName = (key: PropertyType): PropertyTypeDisplayName => {
  const mapper: Record<PropertyType, PropertyTypeDisplayName> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'N/A': 'N/A',
    SINGLE_FAMILY_HOME: 'Single-family Home',
    MULTI_FAMILY_HOME: 'Multi-family Home',
    CONDOMINIUM: 'Condominium',
    APARTMENT: 'Apartment',
    MANUFACTURED_HOME: 'Mobile Home',
    TOWNHOUSE: 'Townhouse',
    STUDENT_HOUSING: 'Room/Dorm',
    OTHERS: 'Other',
  }
  return mapper[key]
}
