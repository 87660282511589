import type {
  IAgentReviewSummary,
  AgentReviewSummaryAboutMeSection,
  AgentReviewSummaryState,
} from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { prioritizeCompliment } from './utils'

export const selectAgentReviewSummaryState: Selector<AgentReviewSummaryState> = state =>
  state.agentReviewSummary

export const selectAgentReviewSummaryData: Selector<IAgentReviewSummary> = createSelector(
  selectAgentReviewSummaryState,
  agentReview => agentReview.data,
)

export const selectAgentReviewSummaryStatus: Selector<RequestStatus> = createSelector(
  selectAgentReviewSummaryState,
  (agentReviewState: AgentReviewSummaryState) => agentReviewState.status || 'initial',
)

export const selectAgentReviewSummary: Selector<IAgentReviewSummary> = createSelector(
  selectAgentReviewSummaryData,
  (agentReview: IAgentReviewSummary) => {
    const {
      average,
      totalReviews,
      star,
      compliments,
      status: { pending, published, total },
    } = agentReview

    const prioritizedCompliment = prioritizeCompliment(compliments)

    return {
      average,
      totalReviews,
      star,
      compliments: prioritizedCompliment,
      status: {
        pending: pending ?? 0,
        published: published ?? 0,
        total: total ?? 0,
      },
    }
  },
)

export const selectAgentReviewSummaryTotalReviews: Selector<number> = createSelector(
  selectAgentReviewSummaryState,
  (agentReview: AgentReviewSummaryState) => agentReview?.data?.totalReviews ?? 0,
)
export const selectAgentReviewSummaryForAboutMeSection: Selector<AgentReviewSummaryAboutMeSection> =
  createSelector(selectAgentReviewSummaryData, (agentReview: IAgentReviewSummary) => {
    const { average, totalReviews } = agentReview
    const percentage = average * 20
    return {
      totalReviews,
      percentage,
    }
  })
