import Tracker, { AmplitudeTracker, UserRecorderTracker, GTMTracker } from '@rentspree/tracker'
import Amplitude from 'amplitude-js'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import set from 'lodash/set'

import env from 'env'

interface IProfileItem<T> {
  value: T
  setOnce?: boolean
}

export const generateAmplitudeProfileItem = <T>(value: T, setOnce = false): IProfileItem<T> => ({
  value,
  setOnce,
})

export const createAmplitudeTracker = (): AmplitudeTracker => {
  const amplitudeTracker = new AmplitudeTracker({
    apiKey: env.AMPLITUDE_API_KEY,
    amplitudeSDK: Amplitude,
    amplitudeConfig: {
      domain: env.AMPLITUDE_COOKIE_DOMAIN,

      // Always capture a new UTM value
      saveParamsReferrerOncePerSession: false,
      includeUtm: true,
    },
    mapUserIdentity: (profile: IProfile) => get(profile, '_id', ''),
    mapUserProfile: (profile: IProfile) => {
      const initProfile: Partial<Record<keyof IProfile, IProfileItem<unknown>>> = {
        email: generateAmplitudeProfileItem(profile.email),
        phone: generateAmplitudeProfileItem(profile.phone),
        createdAt: generateAmplitudeProfileItem(profile.createdAt, true),
        userType: generateAmplitudeProfileItem(profile.userType),
        registeredUserType: generateAmplitudeProfileItem(profile.registeredUserType),
      }
      if (!isEmpty(profile.firstName)) {
        set(initProfile, 'firstName', generateAmplitudeProfileItem(profile.firstName))
      }
      if (!isEmpty(profile.lastName)) {
        set(initProfile, 'lastName', generateAmplitudeProfileItem(profile.lastName))
      }
      if (!isEmpty(profile.experiments)) {
        profile?.experiments?.forEach(({ name, value }) => {
          initProfile[name] = { value }
        })
      }
      return omitBy(initProfile, item => {
        const { value } = item
        return isEmpty(value)
      })
    },
  })
  return amplitudeTracker
}

export const createTracker = (): Tracker | undefined => {
  if (typeof window === 'undefined') return undefined
  const userRecorderTracker = new UserRecorderTracker()
  const amplitudeTracker = createAmplitudeTracker()
  const gtmTracker = new GTMTracker({
    trackingId: env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
    mapUserProfile: (profile: IProfile) => ({
      id: get(profile, '_id'),
      email: get(profile, 'email'),
    }),
  })
  const tracker = new Tracker()

  tracker.registerTracker(gtmTracker)
  tracker.registerTracker(amplitudeTracker)
  tracker.registerTracker(userRecorderTracker)
  return tracker
}

export default createTracker()
