/* eslint-disable no-underscore-dangle */
import type {
  ISendEmailToAgentError,
  ISendEmailToAgentRequestPayload,
  ISendEmailToAgentResponse,
} from './types'
import type { PostLeadRCMBody } from 'api/instances/rcm'
import type { AxiosResponse } from 'axios'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { publicMessagingApiInstance } from 'api'

import { NAME } from './constant'
import { sendRcm } from './utils'

export const sendEmailToAgent = createAsyncThunk(
  `${NAME}/SEND_EMAIL_TO_AGENT`,
  async (data: ISendEmailToAgentRequestPayload, { rejectWithValue }) => {
    const { sender, recipient, message, property } = data
    const source = 'agent-profile'
    const postLeadRCMBody: PostLeadRCMBody = {
      email: sender.email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      owner_id: recipient?._id,
      firstName: sender.fullName,
      mobileNumber: sender.phone,
      source,
      automated: true,
    }
    try {
      const response: AxiosResponse<ISendEmailToAgentResponse> =
        await publicMessagingApiInstance.put('/conversations/send-email', {
          sender,
          recipient,
          message,
          source,
        })

      sendRcm({ postLeadRCMBody, property }).catch(() => true)

      return response
    } catch (e: unknown) {
      const error = e as AxiosResponse<ISendEmailToAgentError>
      if (!error?.data) {
        throw e
      }
      return rejectWithValue(error.data)
    }
  },
)
export const asyncActions = {
  sendEmailToAgent,
}
