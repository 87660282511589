/* eslint-disable camelcase */
import type { IAnyError } from '@rentspree/axios-refresh-token'
import type { AxiosResponse } from 'axios'

import { axiosResponse } from '@rentspree/axios'
import axios from 'axios'

import env from 'env'

import { handleAuthorization } from '../api-handler'
import { request } from '../request'

export type PublicListingDataToRCM = {
  id: string
  address: string
}

interface IPostLeadRCMBody {
  email: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  owner_id: string
  firstName: string
  mobileNumber?: string
  source: string
  property?: PublicListingDataToRCM
  automated: boolean
}

type AxiosResponseInterceptor = (res: AxiosResponse) => AxiosResponse['data']

/* NOTE: line error on @rentspree/axios@typescript */
// eslint-disable-next-line
const axiosResponseInterceptor: AxiosResponseInterceptor = axiosResponse.response

const rcmApiInstance = axios.create({
  baseURL: env.RCM_API_BASE_URL,
})

rcmApiInstance.interceptors.request.use(request)
rcmApiInstance.interceptors.response.use(axiosResponseInterceptor, (error: IAnyError) =>
  handleAuthorization(error),
)

export { rcmApiInstance }

export type { IPostLeadRCMBody as PostLeadRCMBody }
