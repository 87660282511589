import type { IAgentReviewList, AgentReviewListState, IReview, IReviewItem } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'
import { get } from 'lodash'

export const selectAgentReviewListState: Selector<AgentReviewListState> = state =>
  state.agentReviewList

export const selectAgentReviewListData: Selector<IAgentReviewList> = createSelector(
  selectAgentReviewListState,
  agentReviewList => agentReviewList.data,
)

export const selectAgentReviewListStatus: Selector<RequestStatus> = createSelector(
  selectAgentReviewListState,
  (agentReviewListState: AgentReviewListState) => agentReviewListState.status || 'initial',
)
export const selectAgentReviewList: Selector<IReviewItem[]> = createSelector(
  selectAgentReviewListState,
  (agentReviewState: AgentReviewListState) =>
    agentReviewState.data?.data.map((review: IReview) => {
      const reviewerName = review.reviewer?.name ?? ''
      const name = reviewerName.split(' ') as unknown as string
      const firstName = name[0]
      const lastName = name.length > 1 ? name[1] : ''

      const transactionLogs = get(review, 'reviewTransactionLogs', [])
      const filteredLog = transactionLogs.filter(log => log.action === 'submitted_review')
      const date = filteredLog.length > 0 && get(filteredLog[0], 'created_at')
      const reviewDate = date ? new Date(date) : undefined

      const neededReviewInfo: IReviewItem = {
        id: get(review, '_id'),
        reviewer: {
          firstName,
          lastName,
          role: review.reviewer?.role,
          email: review.reviewer?.email,
        },
        reviewDate,
        star: review.star,
        compliments: review.compliments,
        recommendation: review.recommendation,
      }

      return neededReviewInfo
    }),
)

export const selectAgentReviewListAvailable: Selector<boolean> = createSelector(
  selectAgentReviewListState,
  (agentReviewListState: AgentReviewListState) => agentReviewListState.data?.data?.length > 0,
)

export const selectAgentReviewListPagination: Selector<IAgentReviewList['pagination']> =
  createSelector(
    selectAgentReviewListState,
    (agentReviewListState: AgentReviewListState) => agentReviewListState.data?.pagination,
  )
