import type { ICompliment } from './types'

import { ORDER_COMPLIMENTS } from './constant'

export type OrderCompliment = {
  compliment: string
  total: number
}

export const prioritizeCompliment = (compliments: ICompliment[]): OrderCompliment[] => {
  const orderCompliments = ORDER_COMPLIMENTS
  return orderCompliments.map(orderCompliment => {
    const complimentItem = compliments.find(({ compliment }) => compliment === orderCompliment)
    return {
      compliment: orderCompliment,
      total: complimentItem?.total ?? 0,
    }
  })
}
