import type { GetAgentReviewError, GetAgentReviewResponse } from './types'
import type { AxiosResponse } from 'axios'

import { sanitizeUrl } from '@braintree/sanitize-url'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { agentReviewApiInstance } from 'api'

import { NAME } from './constant'

const DEFAULT_PAGE_SIZE = 5

export interface IPayload {
  agentProfileSlug: string
  page?: string | string[] | undefined
}

const generateAgentReviewPaginationQueryString = (page: string | string[] | undefined) => {
  if (!page) return `page=1&perPage=${DEFAULT_PAGE_SIZE}`
  const selected = Array.isArray(page) ? page[0] : page
  return `page=${selected}&perPage=${DEFAULT_PAGE_SIZE}`
}

export const getAgentReviewList = createAsyncThunk(
  `${NAME}/GET_AGENT_REVIEW_LIST`,
  async (payload: IPayload, { rejectWithValue }) => {
    const { agentProfileSlug, page = '1' } = payload

    try {
      const sanitisedSlug: string = !isEmpty(agentProfileSlug)
        ? sanitizeUrl(agentProfileSlug)
        : agentProfileSlug

      const queryString = generateAgentReviewPaginationQueryString(page)

      const response = await agentReviewApiInstance.get<unknown, GetAgentReviewResponse>(
        `/public/${sanitisedSlug}/reviews?status[]=published&${queryString}`,
      )
      return response
    } catch (e: unknown) {
      const error = e as AxiosResponse<GetAgentReviewError>
      if (!error?.data) {
        throw e
      }
      // eslint-disable-next-line no-console
      console.log('getAgentReview error', error)
      return rejectWithValue(error.data)
    }
  },
)

export const asyncActions = {
  getAgentReviewList,
}
