import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
html,
  body {
    margin: 0;
    height: auto;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    @media (max-width: 520px) {
      height: 100%;
    }
  }

  body {
    font-family: Source Sans Pro, sans-serif, "simple-line-icons";
      /* padding: 10px; */
      @media (max-width: 520px) {
      padding: 0px;
    }
  }

  body.fontLoaded {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  body.no-scroll {
    overflow: hidden !important;
  }
  
  p,
  label,
  button {
    font-family: "Source Sans Pro", Georgia, Times, "Times New Roman", serif;
  }

  h {
    font-family: Merriweather, sans-serif;
  }

  a {
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
  }
`

export default GlobalStyle
