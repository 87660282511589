import type { IToastState, IToast } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'

import { v4 as uuidv4 } from 'uuid'

import { createSlice } from '@redux'

import { NAME } from './constants'

export const initialState: IToastState = {
  toasts: [],
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<IToast>) {
      const id = uuidv4()
      state.toasts.push({ ...action.payload, id })
    },
    closeToast(state, action: PayloadAction<string>) {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
    },
  },
})

export const { reducer } = slice
export * from './selectors'
export const { actions } = slice

export default { [NAME]: reducer }
