import { useEffect } from 'react'

import { useDispatch } from 'store'
import { actions } from 'store/users'

type UseTracker = <T>(eventName: string, eventProperties?: T) => void

export const useTracker = (): UseTracker => {
  const dispatch = useDispatch()

  // eslint-disable-next-line
  return (eventName: string, eventProperties?: any) => {
    // eslint-disable-next-line
    dispatch(actions.event({ eventName, eventProperties }))
  }
}

export const useTrackEnterPage = <E>(eventName: string, eventProperties?: E): void => {
  const tracker = useTracker()

  useEffect(() => {
    tracker(eventName, eventProperties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
