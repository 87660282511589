import { axiosAfterLoginRequest } from '@rentspree/axios'

export const request = axiosAfterLoginRequest(
  { accessTokenKey: 'access_token' },
  {
    headers: {
      /*
       * NOTE:
       * breaking change due to incompatible in 3rd party diff changes
       * (axios @0.21.1 -> @0.23.0)
       */
      /* eslint-disable-next-line */
      // @ts-ignore
      post: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    },
  },
)

export default request
