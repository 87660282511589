import type { Selector as DefaultSelector, ThunkAction, Action } from '@reduxjs/toolkit'

import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import { trackerMiddleware } from './middleware/tracker'
import { trackerIdentifyUserMiddleware } from './middleware/tracker-identify'
import reducer, { initialStoreState } from './reducer'

export const makeStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(trackerIdentifyUserMiddleware, trackerMiddleware),
  })

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType> = ThunkAction<ReturnType, AppState, unknown, Action>

export type Selector<T> = DefaultSelector<AppState, T>

export const wrapper = createWrapper<AppStore>(makeStore)

export { initialStoreState }
