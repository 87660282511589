import type { IListingCardData, IListingListData, ListingListState } from './types'
import type { Selector, AppState } from 'store'

import { createSelector } from '@reduxjs/toolkit'
import { generateAddress } from '@rentspree/helper'
import isEmpty from 'lodash/isEmpty'

import env from 'env'

import { initialState } from './initial-state'
import { selectPropertyTypeDisplayName } from './utils'

export const selectListingListState: Selector<ListingListState> = (state: AppState) =>
  state.listingList ?? initialState

export const selectListingListData: Selector<IListingListData> = createSelector(
  selectListingListState,
  (listingState: ListingListState) => {
    if (isEmpty(listingState.data)) return initialState.data
    return listingState.data
  },
)

export const selectIsListingListExist: Selector<boolean> = createSelector(
  selectListingListData,
  (listingListData: IListingListData) => listingListData.data.length > 0,
)

export const selectListingListFetchState: Selector<RequestStatus> = createSelector(
  selectListingListState,
  (listingState: ListingListState) => listingState.status || 'initial',
)

export const selectListingListForListingCard: Selector<IListingCardData[]> = createSelector(
  selectListingListData,
  (listingListData: IListingListData) =>
    listingListData.data.map((listing: IPublicListing) => {
      const { id, leasingInformation, propertyAddress = {}, propertyDetails, slug = '' } = listing
      const { rentAmount } = leasingInformation
      const { bathrooms, bedrooms, propertyType, squareFeet } = propertyDetails
      const publicListingUrl = `${env.BASE_URL}/listing/${slug}`
      const neededListingData: IListingCardData = {
        id,
        rentAmount,

        // TO-DO: fix 'generateAddress' in @rentspree/helper to return only string
        propertyAddress: generateAddress(propertyAddress) ?? '',
        propertyType: selectPropertyTypeDisplayName(propertyType),
        propertyDetail: `${bedrooms} Bd | ${bathrooms} Ba${
          squareFeet ? ` | ${squareFeet} Sqft.` : ''
        }`,
        photo: listing.photos?.[0] || '',
        publicListingUrl,
        meta: {
          propertyAddress,
          bathrooms,
          bedrooms,
          squareFeet,
        },
      }
      return neededListingData
    }),
)

export const selectListingListPagination: Selector<IListingListData['pagination']> = createSelector(
  selectListingListData,
  (listingListData: IListingListData) => listingListData.pagination,
)
