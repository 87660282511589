import type { IPublicMessagingState } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constant'

export const selectPublicMessagingState: Selector<IPublicMessagingState> = state => state[NAME]

export const selectPublicMessagingApiStatus: Selector<RequestStatus> = createSelector(
  selectPublicMessagingState,
  publicMessagingState => publicMessagingState.publicMessageStatus,
)

export const selectScheduleTourApiStatus: Selector<RequestStatus> = createSelector(
  selectPublicMessagingState,
  scheduleTourState => scheduleTourState.scheduleTourStatus,
)
