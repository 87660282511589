import type { AxiosResponse } from 'axios'

import { axiosResponse } from '@rentspree/axios'
import axios from 'axios'

import env from 'env'

import { request } from '../request'

type AxiosResponseInterceptor = (res: AxiosResponse) => AxiosResponse['data']

/* NOTE: line error on @rentspree/axios@typescript */
// eslint-disable-next-line
const axiosResponseInterceptor: AxiosResponseInterceptor = axiosResponse.response

const publicMessagingApiInstance = axios.create({
  baseURL: `${env.USER_API_URL}/api/v2/message`,
})

publicMessagingApiInstance.interceptors.request.use(request)
publicMessagingApiInstance.interceptors.response.use(axiosResponseInterceptor)

export { publicMessagingApiInstance }

export default publicMessagingApiInstance
