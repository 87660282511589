import type { IFeatureFlagData, FeatureFlagState } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constant'

export const selectFeatureFlagState: Selector<FeatureFlagState> = state => state[NAME]

export const selectFeatureFlagData: Selector<IFeatureFlagData> = createSelector(
  selectFeatureFlagState,
  featureFlagState => featureFlagState.data,
)
