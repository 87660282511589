import type { AgentReviewPage, AgentReviewPageState, IReviewItem } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'
import { generateFullName } from '@rentspree/helper'
import get from 'lodash/get'

import env from 'env'

import { AGENT_FULL_NAME_DESCRIPTION, STAR } from './constant'

export const selectAgentReviewPageState: Selector<AgentReviewPageState> = state =>
  state.agentReviewPage

export const selectAgentReviewPage: Selector<AgentReviewPage> = createSelector(
  selectAgentReviewPageState,
  (agentReviewPageState: AgentReviewPageState) => agentReviewPageState.data,
)

export const selectAgentReviewPageStatus: Selector<RequestStatus> = createSelector(
  selectAgentReviewPageState,
  (agentReviewPageState: AgentReviewPageState) => agentReviewPageState.status || 'initial',
)

export const selectAgentReview: Selector<IReviewItem> = createSelector(
  selectAgentReviewPage,
  (review: AgentReviewPage) => {
    const id = get(review, '_id')
    const reviewerName = get(review, 'reviewer.name', '') as string
    const date = get(review, 'transactionLog.created_at') as string

    const name = reviewerName.split(' ')
    const firstName = name[0]
    const lastName = name.length > 1 ? name[1] : ''
    const reviewDate = date ? new Date(date) : undefined

    const neededReviewInfo: IReviewItem = {
      id,
      reviewer: {
        firstName,
        lastName,
        role: review.reviewer?.role,
        email: review.reviewer?.email,
      },
      reviewDate,
      star: review?.star,
      compliments: review?.compliments,
      recommendation: review?.recommendation,
    }

    return neededReviewInfo
  },
)

export const selectAgentProfile: Selector<IAgentProfile> = createSelector(
  selectAgentReviewPage,
  (agentReviewPageData: AgentReviewPage) => agentReviewPageData?.agentProfile ?? {},
)

export const selectAgentProfileFullName: Selector<string> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) => {
    const { firstName, lastName } = agentProfileData
    return generateFullName({ firstName, lastName }) || ''
  },
)

export const selectAgentVerifiedStatus: Selector<boolean> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) => agentProfileData?.isVerified ?? false,
)

export const selectAgentProfileAboutMe: Selector<IAgentProfileAboutMe> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) => {
    const { firstName, lastName, headline, aboutMe, coverImage } = agentProfileData
    return {
      firstName,
      lastName,
      headline,
      aboutMe,
      coverImage,
    }
  },
)

export const selectAgentProfileImage: Selector<string> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) => agentProfileData?.image || '/profile/default-profile.svg',
)

export const selectAgentCoverImage: Selector<string> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) =>
    agentProfileData?.coverImage || '/profile/default-cover-image.png',
)

export const selectAgentProfileHeaderTitle: Selector<string> = createSelector(
  [selectAgentProfile, selectAgentProfileFullName],
  (agentProfileData: IAgentProfile, agentFullName: string) => {
    const { headline } = agentProfileData
    const headerTitle = `${agentFullName}${headline ? ` ${headline}` : ''}`
    return headerTitle
  },
)

export const selectAgentProfileSlug: Selector<string> = createSelector(
  selectAgentProfile,
  (agentProfileData: IAgentProfile) => agentProfileData.customSlug || agentProfileData.slug || '',
)

export const selectSharingMetaProperties: Selector<ISharingMetaProperties> = createSelector(
  [selectAgentReviewPage, selectAgentProfileFullName],
  (agentReviewPageData: AgentReviewPage, generatedName: string) => {
    const {
      _id,
      star,
      recommendation,
      agentProfile: { coverImage, slug } = {},
    } = agentReviewPageData

    const agentFullName = generatedName ? `${generatedName} ` : ''
    const title = `${agentFullName}${AGENT_FULL_NAME_DESCRIPTION}`
    const reviewStar = star ? STAR.repeat(star) : ''
    const reviewRecommendation = recommendation ? ` "${recommendation}"` : ''
    const description = reviewStar ? `${reviewStar}${reviewRecommendation}` : ''
    const image = coverImage || '/profile/default-cover-image.png'
    const url = slug && _id ? `${env.DOMAIN_NAME}/profile/${slug}/review/${_id}` : ''

    return { title, description, image, url }
  },
)
