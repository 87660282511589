import type { AgentProfileState } from './types'
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { getAgentProfile, asyncActions } from './async-actions'
import { NAME } from './constant'

export const initialState: AgentProfileState = {
  error: null,
  status: 'initial',
  data: {},
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setAgentProfile(state, action: PayloadAction<IAgentProfile>) {
      state.data = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AgentProfileState>): void => {
    builder.addCase(getAgentProfile.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getAgentProfile.fulfilled, (state, action) => {
      const { listingList, ...agentProfile } = action.payload
      state.status = 'fulfilled'
      state.data = agentProfile
    })
    builder.addCase(getAgentProfile.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'

export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: slice.reducer }
