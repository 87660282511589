import type { IAgentReviewData, IAgentReviewFetchError, IAgentReviewState } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { getAgentReview, submitAgentReview } from './async-actions'
import { NAME } from './constant'

export const initialState: IAgentReviewState = {
  fetchingStatus: 'initial',
  submittingStatus: 'initial',
  data: {} as IAgentReviewData,
}

const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setSubmittingStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.submittingStatus = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAgentReview.pending, state => {
      state.fetchingStatus = 'pending'
    })

    builder.addCase(getAgentReview.fulfilled, (state, action) => {
      state.fetchingStatus = 'fulfilled'
      state.data = action.payload
    })

    builder.addCase(getAgentReview.rejected, (state, action) => {
      state.fetchingStatus = 'rejected'
      state.fetchingError = action.payload as IAgentReviewFetchError
    })

    builder.addCase(submitAgentReview.pending, state => {
      state.submittingStatus = 'pending'
    })

    builder.addCase(submitAgentReview.fulfilled, state => {
      state.submittingStatus = 'fulfilled'
    })

    builder.addCase(submitAgentReview.rejected, (state, action) => {
      state.submittingStatus = 'rejected'
      state.submittingError = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'
export const actions = { ...slice.actions, getAgentReview, submitAgentReview }

export default { [NAME]: slice.reducer }
