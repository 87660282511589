import type { IAnalyticDataState } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constant'

export const selectAnalyticDataState: Selector<IAnalyticDataState> = state => state[NAME]

export const selectAnalyticData: Selector<IAnalyticDataState> = createSelector(
  [selectAnalyticDataState],
  data => data,
)
