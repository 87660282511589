import type { IMessagingState } from './types'
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { asyncActions, sendDirectMessage } from './async-actions'
import { NAME } from './constants'

export const initialState: IMessagingState = {
  error: null,
  status: 'initial',
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IMessagingState>) => {
    builder.addCase(sendDirectMessage.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(sendDirectMessage.fulfilled, state => {
      state.status = 'fulfilled'
    })
    builder.addCase(sendDirectMessage.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.payload as RequestError
    })
  },
})

export const { reducer } = slice
export * from './selectors'
export const actions = {
  ...slice.actions,
  ...asyncActions,
}

export default { [NAME]: reducer }
