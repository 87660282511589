import type { StatsigInitializeValues } from 'utils/feature-flag/instance'

import { StatsigClient } from '@statsig/js-client'
import { StatsigProvider } from '@statsig/react-bindings'
import { useState } from 'react'

type FeatureFlagProviderProps = {
  initializeValues: StatsigInitializeValues
  children: React.ReactNode
}

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  initializeValues,
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statsigClient, setStatsigClient] = useState(() => {
    if (!initializeValues) {
      return null
    }

    const { user, values, clientKey } = initializeValues

    const statsigClientInstance = new StatsigClient(clientKey, user)

    statsigClientInstance.dataAdapter.setData(values)
    statsigClientInstance.initializeSync()

    return statsigClientInstance
  })

  if (statsigClient) {
    return <StatsigProvider client={statsigClient}>{children}</StatsigProvider>
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
