import { combineReducers } from '@reduxjs/toolkit'

import agentProfileReducer, { initialState as initialAgentProfile } from './agent-profile'
import agentReviewReducer, { initialState as initialAgentReview } from './agent-review'
import agentReviewListReducer, { initialState as initialAgentReviewList } from './agent-review-list'
import agentReviewPageReducer, { initialState as initialAgentReviewPage } from './agent-review-page'
import agentReviewSummaryReducer, {
  initialState as initialAgentReviewSummary,
} from './agent-review-summary'
import analyticDataReducer, { initialState as initialAnalyticDataState } from './analytic-data'
import featureFlagReduce, { initialState as initialFeatureFlag } from './feature-flag'
import listingListReducer from './listing-list'
import { initialState as initialListingList } from './listing-list/initial-state'
import messagingReducer, { initialState as initialMessaging } from './messaging'
import publicMessagingReducer, { initialState as initialPublicMessaging } from './public-messaging'
import toastReducer, { initialState as initialToast } from './toast'
import userReducer, { initialState as initialUser } from './users'

const rootReducer = combineReducers({
  ...agentProfileReducer,
  ...featureFlagReduce,
  ...userReducer,
  ...analyticDataReducer,
  ...messagingReducer,
  ...toastReducer,
  ...publicMessagingReducer,
  ...listingListReducer,
  ...agentReviewListReducer,
  ...agentReviewPageReducer,
  ...agentReviewSummaryReducer,
  ...agentReviewReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const initialStoreState: RootState = {
  agentProfile: initialAgentProfile,
  users: initialUser,
  analyticData: initialAnalyticDataState,
  messaging: initialMessaging,
  toast: initialToast,
  publicMessaging: initialPublicMessaging,
  listingList: initialListingList,
  featureFlag: initialFeatureFlag,
  agentReviewList: initialAgentReviewList,
  agentReviewPage: initialAgentReviewPage,
  agentReviewSummary: initialAgentReviewSummary,
  agentReview: initialAgentReview,
}

export default rootReducer
