import type { GetAgentReviewSummaryError, GetAgentReviewSummaryResponse } from './types'
import type { AxiosResponse } from 'axios'

import { sanitizeUrl } from '@braintree/sanitize-url'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import { agentReviewApiInstance } from 'api'

import { NAME } from './constant'

export interface IPayload {
  agentProfileSlug: string
  page?: string | string[] | undefined
}

export const getAgentReviewSummary = createAsyncThunk(
  `${NAME}/GET_AGENT_REVIEW_SUMMARY`,
  async (payload: IPayload, { rejectWithValue }) => {
    const { agentProfileSlug } = payload

    try {
      const sanitisedSlug: string = !isEmpty(agentProfileSlug)
        ? sanitizeUrl(agentProfileSlug)
        : agentProfileSlug

      const response = await agentReviewApiInstance.get<unknown, GetAgentReviewSummaryResponse>(
        `/public/${sanitisedSlug}/summary?status[]=published`,
      )
      return response
    } catch (e: unknown) {
      const error = e as AxiosResponse<GetAgentReviewSummaryError>
      if (!error?.data) {
        throw e
      }
      // eslint-disable-next-line no-console
      console.log('getAgentReviewSummary error', error)
      return rejectWithValue(error.data)
    }
  },
)

export const asyncActions = {
  getAgentReviewSummary,
}
