import type { IMessagingState } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constants'

export const selectMessagingState: Selector<IMessagingState> = state => state[NAME]

export const selectMessagingStatus: Selector<RequestStatus> = createSelector(
  selectMessagingState,
  messagingState => messagingState.status ?? 'initial',
)

export const selectMessagingError: Selector<RequestError | null> = createSelector(
  selectMessagingState,
  messagingState => messagingState.error ?? null,
)
