import type { IGetProfileResponse } from './types'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { userApiInstance } from 'api'

import { NAME } from './constant'

export const getUserProfile = createAsyncThunk(`${NAME}/GET_USER`, async () => {
  const response = await userApiInstance.get<unknown, IGetProfileResponse>('/me')
  return response
})

export const asyncActions = {
  getUserProfile,
}
