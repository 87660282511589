import type { TokenObj } from '@rentspree/cookie'

import { getLocalItem, removeAllLocalItem, setOption } from '@rentspree/cookie'

import { STORAGE, cookieOption } from 'constant'
import env from 'env'

export const loadAccessToken = (): IUserToken => {
  if (typeof window === 'undefined') {
    return { accessToken: null, refreshToken: null, expiresIn: null }
  }
  const userToken = getLocalItem(STORAGE.USER_TOKEN)
  try {
    const o: TokenObj = typeof userToken === 'string' ? <TokenObj>JSON.parse(userToken) : userToken
    return {
      accessToken: o.access_token,
      refreshToken: o.refresh_token,
      expiresIn: o.expires_in,
    }
  } catch (e) {
    return {
      accessToken: null,
      refreshToken: null,
      expiresIn: null,
    }
  }
}

export const removeAccessToken = (): void => {
  if (typeof window === 'undefined') return
  setOption(cookieOption(env.DOMAIN_NAME ?? 'localhost'))
  removeAllLocalItem('rentspree')
  localStorage.clear()
}
