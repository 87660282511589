/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import disableScroll from 'disable-scroll'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'

export type CustomStyled = {
  wrapper?: React.CSSProperties
  overlay?: React.CSSProperties
  content?: React.CSSProperties
}

export interface IModalProps {
  children: React.ReactNode
  isOpen: boolean
  onOverlayClick: React.MouseEventHandler<HTMLDivElement>
  customStyled?: CustomStyled
  elementId: 'root' | string
}

export interface IModalOptions {
  canScroll?: boolean
  hasCloseOnOverlayClick?: boolean
  customStyled?: CustomStyled
}

export type UseModal = (
  elementId: string,
  options?: IModalOptions,
) => [
  ModalWrapper: React.FC<{ children: React.ReactNode }>,
  open: () => void,
  close: () => void,
  isOpen: boolean,
]

const wrapperStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}

const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 100000,
}

const containerStyle: React.CSSProperties = {
  position: 'relative',
  zIndex: 100001,
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen = false,
  onOverlayClick,
  customStyled = {},
  elementId = 'root',
}) => {
  if (isOpen === false) {
    return null
  }

  const { wrapper = {}, overlay = {}, content = {} } = customStyled

  return createPortal(
    <div style={{ ...wrapperStyle, ...wrapper }}>
      <div style={{ ...overlayStyle, ...overlay }} onClick={onOverlayClick} />
      <div style={{ ...containerStyle, ...content }}>{children}</div>
    </div>,
    document.getElementById(elementId) as HTMLElement,
  )
}

export const useModal: UseModal = (elementId = 'root', options = {}) => {
  const { canScroll = true, hasCloseOnOverlayClick = true, customStyled = {} } = options
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = useCallback(() => {
    setIsOpen(true)
    if (!canScroll) {
      disableScroll.on()
    }
  }, [setIsOpen, canScroll])

  const close = useCallback(() => {
    setIsOpen(false)
    if (!canScroll) {
      disableScroll.off()
    }
  }, [setIsOpen, canScroll])

  const onOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (hasCloseOnOverlayClick) {
        close()
      }
    },
    [hasCloseOnOverlayClick, close],
  )

  const ModalWrapper = useCallback(
    ({ children }) => (
      <Modal
        isOpen={isOpen}
        onOverlayClick={onOverlayClick}
        elementId={elementId}
        customStyled={customStyled}
      >
        {children}
      </Modal>
    ),
    [isOpen, onOverlayClick, customStyled, elementId],
  )

  return [ModalWrapper, open, close, isOpen]
}
