/* eslint-disable no-underscore-dangle */
import type { UserState, IUserData, IUserProfile, UserType } from './types'
import type { Selector } from 'store'

import { createSelector } from '@reduxjs/toolkit'

import { NAME } from './constant'

export const selectUserState: Selector<UserState> = state => state[NAME]

export const selectUserStateData: Selector<IUserData> = createSelector(
  selectUserState,
  userState => userState.data,
)

export const selectUserProfile: Selector<IUserProfile> = createSelector(
  selectUserStateData,
  userData => userData.profile,
)

export const selectUserStateStatus: Selector<RequestStatus> = createSelector(
  selectUserState,
  userState => userState.status || 'initial',
)

export const selectUserProfileUserType: Selector<UserType> = createSelector(
  selectUserProfile,
  userProfile => userProfile?.userType ?? 'landlord',
)

export const selectIsUserDataFetched: Selector<boolean> = createSelector(
  selectUserStateStatus,
  userStateStatus => userStateStatus !== 'initial' && userStateStatus !== 'pending',
)

export const selectUserId: Selector<string | undefined> = createSelector(
  selectUserProfile,
  user => user?._id,
)
