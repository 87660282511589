export const PUBLIC_PAGE = {
  CONTACT_TO_AGENT: 'Contact to Agent',
  ENTER_PUBLIC_PROFILE: 'Enter to public profile',
  CLICK_SOCIAL_BUTTON: 'click %socialType% button',
  CLICK_PRIMARY_WEBSITE_LINK: 'click link primary',
  CLICK_SECONDARY_WEBSITE_LINK: 'click link secondary',
  CLICK_SEND_MESSAGE: 'click send a message',
  CLICK_SIGN_UP: 'click sign up',
  CLICK_GO_TO_MESSAGE: 'click go to messages',
  CLICK_VIEW_LISTING_PAGE: 'click view listing page',
  CLICK_CALL_TO_AGENT: 'click call to agent',
}

export const AGENT_REVIEW = {
  EVENT: {
    ENTER_RATE_AND_REVIEW_MODAL: 'enter rate and review modal',
    CLICK_CLOSE_ON_RATE_AND_REVIEW_MODAL: 'click close on rate and review modal',
    CLICK_SUBMIT_REVIEW: 'click submit review',
    CLICK_I_DID_NOT_WORK_WITH_THIS_AGENT: 'click I did not work with this agent',
    CLICK_VIEW_ALL_REVIEWS: 'click view all reviews',
    CLICK_VIEW_PROFILE: 'click view profile',
    VIEW_AGENT_REVIEW: 'view agent review',
  },
  PROPERTY: {
    APPLICANT: 'applicant',
    STAR_RATING: 'star rating',
    COMPLIMENTS_NAME: 'compliments name',
    ADD_RECOMMENDATION: 'add recommendation',
  },
  FEATURE_NAME: {
    TENANT_SCREENING: 'tenant screening',
    ACCEPT_DENY: 'accept/deny',
    ESIGN: 'esign',
  },
}

export const NAVBAR = {
  LEAVE_PAGE_VIA_NAVBAR: 'leave page via nav bar',
}

export const FOOTER = {
  LEAVE_PAGE_VIA_FOOTER: 'leave page via footer',
}
