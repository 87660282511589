import type { IFeatureFlagActionPayload, FeatureFlagState } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@redux'

import { NAME } from './constant'

export const initialState: FeatureFlagState = {
  error: null,
  status: 'initial',
  data: {},
}

export const slice = createSlice(NAME, {
  name: NAME,
  initialState,
  reducers: {
    setFeatureFlag(state, action: PayloadAction<IFeatureFlagActionPayload>) {
      const { key, value } = action.payload
      state.data[key] = value
    },
  },
})

export const { reducer } = slice
export * from './selectors'
export const { actions } = slice

export default { [NAME]: slice.reducer }
