import type { NextEnv } from './types'

import getConfig from 'next/config'

/* NOTE: next/config provide an 'any' return type */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { publicRuntimeConfig = {} } = getConfig() || {}

const runtimeENV = publicRuntimeConfig as unknown as NextEnv

export default runtimeENV
